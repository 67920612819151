<template>
  <v-card
    outlined
    flat
  >
    <div
      class="pl-6 pr-6 pb-4 pt-4"
    >
      <div class="text-h4 font-weight-bold">
        <v-skeleton-loader
          ref="skeleton"
          type="heading"
          height="40px"
        />
      </div>
      <div class="dash-title dash-single-value-title">
        {{ dash.title }}
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
    name: 'DashLoading',
    props: {
      dash: {
        type: Object,
        default: () => {}
      }
    },
    methods: {
        edit() {
            this.$emit('edit')
        },
        refresh() {
            this.$emit('refresh')
        }
    }
}
</script>
